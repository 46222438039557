import React from 'react';
import aliPic3 from './images/aliTechnologiesPic.png';
import reactLogo from './images/reactLogo.png';
import nodeJsLogo from './images/nodeJsLogo.png';
import pythonLogo from './images/pythonLogo.png';
import expressJsLogo from './images/expressJsLogo.png';
import wordpressLogo from './images/wordpressLogo.png';
import './sectionTechnologies.modules.css';

export default () => {
    return (
        <div id="technologies" className='mainClassTechnologies'>
            <div className='textContentTechnologies'>
                <h3 className='titleTechnologies'>Technologies</h3>
                <p className='sectionTechnologiesFewParagraph'>Here are a few technologies I've been working with recently.</p>

                <div className='technologyContainer'>
                    <img src={reactLogo} className='reactLogo' alt='React Logoo' />
                    <div>
                        <h1 className='titleTechnologyName'>React JS</h1>
                    </div>
                </div>

                <div className='technologyContainer'>
                    <img src={nodeJsLogo} className='nodeLogo' alt='Node Js Logo' />
                    <div>
                        <h1 className='titleTechnologyName'>Node.js</h1>
                    </div>
                </div>

                <div className='technologyContainer'>
                    <img src={pythonLogo} className='nodeLogo' alt='Python Logo' />
                    <div>
                        <h1 className='titleTechnologyName'>Python Flask</h1>
                    </div>
                </div>

                <div className='technologyContainer'>
                    <img src={expressJsLogo} className='nodeLogo' alt='Express Logo' />
                    <div>
                        <h1 className='titleTechnologyName'>Express</h1>
                    </div>
                </div>

                <div className='technologyContainer'>
                    <img src={wordpressLogo} className='nodeLogo' alt='Wordpress Logo' />
                    <div>
                        <h1 className='titleTechnologyName'>Wordpress</h1>
                    </div>
                </div>


            </div>
            <div className='technologyMainImageBackground'>
                <img src={aliPic3} className='imageTechnology' alt='Ali' />
            </div>
        </div>
    )
}