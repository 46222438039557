import React from 'react';
import Navbar from './../navbar/';
import CustomNavbar from './../customNavbar/';
import cover from './cover.jpg';
import uniCover2 from './uniCover2.jpg';
import quizCover1 from './quizCover1.jpg';
import khanpurDam from './khanpurDam.jpg';
import './homeLandingPage.modules.css';
import SectionHero from '../sectionHero/sectionHero';
import SectionAbout from '../sectionAbout/sectionAbout';
import SectionEducation from '../sectionEducation/sectionEducation';
import SectionTechnologies from '../sectionTechnologies/sectionTechnologies';
import SectionFooter from '../sectionFooter/sectionFooter';

export default () => {
    return (
        <div >
            {/* <Navbar /> */}
            <CustomNavbar />
            <div className='hero-nav-container'>
                {/* <Navbar /> */}
                <SectionHero />
            </div>
            <img src={cover} className='coverImage' alt='Ali' />
            <SectionAbout/>
            <img src={uniCover2} className='coverImage' alt='Ali in Uni' />
            <SectionEducation/>
            <img src={quizCover1} className='coverImageQuiz' alt='Ali at Quiz Competition' />
            <SectionTechnologies />
            <img src={khanpurDam} className='coverImageQuiz' alt='Khanpur Dam' />
            <SectionFooter />
        </div>
    )
}