import React from 'react';
import facebookLogo from './images/facebookLogo.png';
import twitterLogo from './images/twitterLogo.png';
import linkedinLogo from './images/linkedinLogo.png';
import githubLogo from './images/githubLogo.png';
import instagramLogo from './images/instagramLogo.png';
import aliFooterPic from './images/aliFooterPic.png';
import './sectionFooter.modules.css';

export default () => {
    return (
        <div id="footer" className='mainClassFooter'>
            <div className='textContentFooter'>
                <h3 className='socialMediaLinksHeading'>Social Media Links</h3>

                <div className='socialMediaIconsContainer'>
                    <a href="https://www.facebook.com/ali.sarib.1" target="_blank"><img src={facebookLogo} className='socialMediaIcon' alt='Facebook Logo' /></a>
                    <a href="https://twitter.com/alisarib1" target="_blank"><img src={twitterLogo} className='socialMediaIcon' alt='Twitter Logo' /></a>
                    <a href="https://www.linkedin.com/in/ali-sarib-22b0a0171/" target="_blank"><img src={linkedinLogo} className='socialMediaIcon' alt='Linkedin Logo' /></a>
                    <a href="https://github.com/alisarib" target="_blank"><img src={githubLogo} className='socialMediaIcon' alt='Github Logo' /></a>
                    <a href="https://www.instagram.com/ali.sarib" target="_blank"><img src={instagramLogo} className='socialMediaIcon' alt='Instagram Logo' /></a>
                </div>
                <p className='sectionFooterWelcomeParagraph'> My inbox is always open, whether you have a question or just want to say hi, I'll try my best to get back to you!</p>
                <a href="mailto:alisarib7@gmail.com">
                    <button className='sayHelloButtonFooter'>Say Hello</button>
                </a>
                <p className='sectionFooterDesignedParagraph'>Designed, Developed and Deployed by <a href="/" className='sectionFooterDesignedByAli'>Ali Sarib</a></p>
            </div>

            <div className='footerMainImageBackground'>
                <img src={aliFooterPic} className='imageFooter' alt='Ali' />
            </div>

            <p className='sectionFooterDesignedParagraphMobile'>Designed, Developed and Deployed by <a href="/" className='sectionFooterDesignedByAli'>Ali Sarib</a></p>

        </div>
    )
}