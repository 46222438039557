import React from 'react';
import dropdownIcon from './dropdownIcon.png';
import './customNavbar.modules.css'
import './../sectionHero/sectionHero.modules.css';


const customNavbar = () => {
  return (<div class="customNavBarContainer">
    <div class="customNavbar">
      <a href="#hero">Home</a>
      <a href="#about">About</a>
      <a href="#education">Education</a>
      <a href="mailto:alisarib7@gmail.com">Contact</a>
    </div>

    <div class="dropdown">
      {/* <button class="dropbtn">Dropdown</button> */}
      <img src={dropdownIcon} className='dropbtn' alt='Dropdown Icon' />
      <div class="dropdown-content">
        <a href="#hero">Home</a>
        <a href="#about">About</a>
        <a href="#education">Education</a>
        <a href="mailto:alisarib7@gmail.com">Contact</a>
      </div>
    </div>

  </div>)
}

export default customNavbar;