import React from 'react';
import aliPic from './images/aliPic2.png';
import './sectionAbout.modules.css';

export default () => {
    return (
        <div id="about" className='mainClassAbout'>
            <div className='textContentAbout'>
                <h3 className='titleAbout'>About</h3>
                <h1 className='titleAboutSection'>Allow me to introduce myself</h1>
                <br/>
                <h6 className='aboutP1'>Hey! I'm <b>Ali Sarib</b>, a self taught techie. After graduating from the Punjab University College of Information Technology <b>(PUCIT)</b>, Lahore, I've been actively involved in the Software Development. </h6>
                <h6 className='aboutP1'>My interests, however, extend beyond the Software Development and I love mentoring technology enthusiasts that are passionate about new ,challenging and cutting-edge technologies to explore and achieve more and to help others getting into the <b>tech industry</b>.</h6>

            </div>
            <div className='aboutMainImageBackground'>
                <img src={aliPic} className='imageAbout' alt='Ali' />
            </div>
        </div>
    )
}