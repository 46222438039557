import React from 'react';
import aliPic3 from './images/aliPic3.png';
import puLogo from './images/puLogo.png';
import pgcLogo from './images/pgcLogo.png';
import spsLogo from './images/spsLogo.png';
import './sectionEducation.modules.css';

export default () => {
    return (
        <div id="education" className='mainClassEducation'>
            <div className='textContentEducation'>
                <h3 className='titleEducation'>Education</h3>

                <div className='educationPlace'>
                    <img src={puLogo} className='instituteImageLogo' alt='Punjab University Logo' />
                    <div>
                        <h1 className='titleEducationInstituteName'>University of the Punjab</h1>
                        <p className='sectionEducationDegreeName'>Bachelor's degree, Information Technology </p>
                        <p className='sectionEducationDegreeYear'>2017 - 2021</p>
                    </div>
                </div>

                <div className='educationPlace'>
                    <img src={pgcLogo} className='pgcImageLogo' alt='Punjab College Logo' />
                    <div>
                        <h1 className='titleEducationInstituteName'>Punjab College of IT</h1>
                        <p className='sectionEducationDegreeName'>ICS</p>
                        <p className='sectionEducationDegreeYear'>2015 - 2017</p>
                    </div>
                </div>

                <div className='educationPlace'>
                    <img src={spsLogo} className='pgcImageLogo' alt='School Logo' />
                    <div>
                        <h1 className='titleEducationInstituteName'>Society Public School</h1>
                        <p className='sectionEducationDegreeName'>Matric</p>
                        <p className='sectionEducationDegreeYear'>2003 - 2015</p>
                    </div>
                </div>


            </div>
            <div className='educationMainImageBackground'>
                <img src={aliPic3} className='imageEducation' alt='Ali' />
            </div>
        </div>
    )
}