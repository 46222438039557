import React from 'react';
import TextLoop from 'react-text-loop';
//import aliPic from './images/aliPic1.png';
// newPP is large file but newP is small 
import aliPic from './images/newP.png';
import './sectionHero.modules.css';

export default () => {
    return (
        <div id="hero" className='mainClassHero'>
            <div className='textContentHero'>
                <h3 className='helloIAm'>Hello I'm</h3>
                <h1 className='titleAli'>Ali Sarib</h1>

                <p>
                    <TextLoop mask={true}>
                        <span className='heroLineSoftwareEngineer'>Software Engineer @ i2c inc</span>
                        <span className='heroLineSoftwareEngineer'>Computer Lecturar @ BEST Academy</span>

                    </TextLoop>
                    {/* and something else. */}
                </p>

                {/* <p className='heroLineSoftwareEngineer'>Software Engineer @ codedvlpr </p> */}
                <p>I design and code beautifully , and I love what I do.</p>
                <a href="mailto:alisarib7@gmail.com">
                <button className='getInTouchButtonHero'>Get in touch</button>
                </a>
            </div>
            <div className='heroMainImageBackground'>
                <img src={aliPic} className='imageHero' alt='Ali' />
            </div>
        </div>
    )
}